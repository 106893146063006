import React, { useEffect } from 'react';
import PageTitle from '../components/atoms/PageTitle';
import Anchor from '../components/atoms/Anchor';
import styled from 'styled-components';
import { mainColor } from '../components/theme';
import HeadBase from '../components/molecules/HeadBase';
import BaseLayoutTemplateECMovie from 'app/src/components/environments/BaseLayoutTemplateECMovie';

type ECMovieSubmittedPageProps = {
  location: {
    state: {
      fromPage?: 'contact';
    };
  };
};

const ECMovieSubmittedPage: React.FC<ECMovieSubmittedPageProps> = ({
  location,
}) => {
  useEffect(() => {
    // うまくいかないのでやめる
    // if (location.state?.fromPage == null) {
    //   navigate('/');
    // }
  }, []);

  return (
    <BaseLayoutTemplateECMovie>
      <HeadBase noindex />

      {/* アイキャッチ */}
      <PageTitle title='Contact' span='EC動画パーラーのお問い合わせ' />

      <MessageWrap>
        <p>お問い合わせは送信されました。</p>
        <p>担当の者よりご連絡差し上げますので今しばらくお待ち下さい。</p>
        <StyledAnchor url='/ec-movie-parlor'>
          EC動画パーラーのページへ戻る
        </StyledAnchor>
      </MessageWrap>
    </BaseLayoutTemplateECMovie>
  );
};

export default ECMovieSubmittedPage;

const MessageWrap = styled.div`
  padding: 56px 1rem 100px;
  text-align: center;
`;

const StyledAnchor = styled(Anchor)`
  margin-top: 146px;
  text-decoration: underline;
  color: ${mainColor};
`;
