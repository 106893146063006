import * as React from 'react';
import styled from 'styled-components';
import { fontSize, borderColor, breakpoint, mainColor } from '../../theme';

interface PageTitleProps {
  url?: string;
  title: string;
  span: string;
  className?: string;
}

const PageTitle: React.FC<PageTitleProps> = ({
  url,
  title,
  span,
  className
}) => (
  <Wrap className={className}>
    {!!url && <Ayecatch url={url} />}
    <Container>
      <h1>{title}</h1>
      <span>{span}</span>
    </Container>
  </Wrap>
);

export default PageTitle;

const Wrap = styled.div`
  position: relative;
  border-bottom: 1px solid ${borderColor};
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    display: block;
    height: 100%;
    width: 40%;
    border-bottom: 1px solid ${mainColor};
  }
`;

const Ayecatch: any = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 230px;
  @media ${breakpoint.sp} {
    height: 200px;
  }
  background: url(${(props: any) => props.url}) center center no-repeat;
  background-size: cover;
`;

const Container = styled.div`
  padding: 1rem 70px;
  @media ${breakpoint.sp} {
    padding: 1rem;
  }
  margin: 0 auto;
  max-width: 1024px;
  display: flex;
  flex-direction: row;
  align-items: center;
  > h1 {
    margin-right: 1rem;
    font-size: 21px;
  }
  > span {
    font-size: 14px;
  }
`;
